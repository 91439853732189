<template>
  <div class="side-navbar" id="mainSideBar">
    <div class="flex items-center justify-between p-4 border-b-2 border-white" style="margin-top: -5px;">
      <div class="flex items-center">
        <img src="../../../assets/image.png" class="h-10 w-10 mr-4" alt="Logo">
        <span id="header-text" class="text-white font-bold">EHPL</span>
      </div>
      <div class="mobile-close text-white items-end" @click="toggleSidebar">
        <i class="fa-solid fa-xmark text-3xl bx-bold"></i>
      </div>
    </div>
    <!-- New License routes -->
    <SideBarTemplate
      v-if="finalUrl && finalUrl === 'newLicense'"
      :isDarkMode="isDarkMode"
      :links="newLicenseSideBarItems"
      :page="'New License'"
    ></SideBarTemplate>
    <!-- End of New License routes -->
    <!-- Renewal Routes -->
    <SideBarTemplate
      v-if="finalUrl && finalUrl === 'renewal'"
      :isDarkMode="isDarkMode"
      :links="renewalSideBarItems"
      :page="'Renewal'"
    ></SideBarTemplate>
    <!--End of Renewal Routes -->

    <!-- Goodstanding routes -->
    <SideBarTemplate
      v-if="finalUrl && finalUrl === 'goodstanding'"
      :isDarkMode="isDarkMode"
      :links="gSSideBarItems"
      :page="'Good Standing'"
    ></SideBarTemplate>
    <!--End of Goodstanding routes -->

    <!--Lost License  routes -->
    <SideBarTemplate
      v-if="finalUrl && finalUrl === 'lostLicense'"
      :isDarkMode="isDarkMode"
      :links="LLicenseSideBarItems"
      :page="'Lost License'"
    ></SideBarTemplate>
    <!--End of Lost License  routes -->


  <!--Profile  routes -->
    <SideBarTemplate
      v-if="finalUrl && finalUrl === 'profile'"
      :isDarkMode="isDarkMode"
      :links="profileSideBarItems"
      :page="'Profile'"
    ></SideBarTemplate>
    <!--End of Profile routes -->

  </div>
</template>

<script> 
import SideBarTemplate from "./SideBar/sideBarTemplate.vue";
import {
  newLicenseSideBarItems,
  renewalSideBarItems,
  gSSideBarItems,
  LLicenseSideBarItems,
  profileSideBarItems
} from "./SideBar/sideBarLinks.js";
export default {
  props: ["finalUrl", "isDarkMode"],
  components: { SideBarTemplate },
  methods: {
    toggleSidebar() {
      let sidenavbar = document.querySelector(".side-navbar");
      let content = document.querySelector(".content");
      let sidenavbarHeader = document.querySelector("#header-text");
      sidenavbar.classList.toggle("active");
      content.classList.toggle("active");
      sidenavbarHeader.classList.toggle("disable-header-text");
    }
  },
  setup() {
    return {
      newLicenseSideBarItems,
      renewalSideBarItems,
      gSSideBarItems,
      LLicenseSideBarItems,
      profileSideBarItems,
    };
  },
};
</script>
